// copy of templates/index-page.js

import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { Link, graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { purple, red } from '@mui/material/colors';

// @material-ui/core components
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

import Layout from '../components/Layout';
import Features from '../components/Features';
import BlogRoll from '../components/BlogRoll';
import FullWidthImage from '../components/FullWidthImage';
import RightHandImage from '../components/RightHandImage';
import Sidebar from '../components/Sidebar';
import Content, { HTMLContent } from '../components/Content';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import RemoveRedEyeTwoToneIcon from '@mui/icons-material/RemoveRedEyeTwoTone';

import logo from '../img/logo.svg';
// import ProductList from '../components/ProductList';

const Section = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	zIndex: 1,
});

const MainContent = styled(Box)({
	// marginTop: 'auto',
	// marginBottom: '50px',
	margin: 'auto 64px 50px',
});

const FeatureOne = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	backgroundColor: purple[200],
	padding: theme.spacing(12),
}));

const CardOne = styled(Box)(({ theme, props }) => ({
	display: 'flex',
}));

// const Purple = purple[200];

// eslint-disable-next-line
export const RMGLandingTemplate = ({
	image,
	title,
	heading,
	subheading,
	mainpitch,
	description,
	intro,
	content,
	contentComponent,
}) => {
	const heroImage = getImage(image) || image;
	const PageContent = contentComponent || Content;

	return (
		<>
			<RightHandImage
				img={heroImage}
				// title={title}
				// subheading={subheading}
				height='100%'
				imgPosition='center center'
			/>
			<Section maxWidth='lg' mx='auto'>
				<MainContent className='mainContent' px={0}>
					<PageContent className='content' content={content} />
					<Grid
						container
						spacing={2}
						sx={{ justifyContent: 'space-between' }}
						py={4}
					>
						<Grid item xs={12} sm={12} md={7} className='section'>
							<Typography my={5} variant='h1' className='title'>
								{mainpitch.title}
							</Typography>
							<Typography variant='body1' className='title'>
								{mainpitch.description}
							</Typography>
							<Typography variant='body1' className='title'>
								testing ... Our technicians are trained in using high quality laser
								welding techniques for fixing eyeglasses and provide fast, while you
								wait repairs for the Perth metro and regional WA.
							</Typography>
							<Typography variant='body1' className='title'>
								'Repair My Glasses' can repair a large variety of accidental or wear
								damage to glasses frames. Call us to discuss your requirements and book
								an appointment to bring your broken glasses into our Mount Lawley
								Studio.
							</Typography>

							<Grid container item spacing={4}>
								<Features gridItems={intro.blurbs} gridWidth={6} />
							</Grid>
						</Grid>
						<Grid item xs={12} sm={12} md={4}>
							<Sidebar sidebarItems={intro.blurbs} className='sidebar' />
						</Grid>
						<Grid container item spacing={4}>
							<Features gridItems={intro.blurbs} />
						</Grid>
					</Grid>
				</MainContent>
			</Section>

			<FeatureOne>
				<Container>
					<Grid
						container
						item
						xs={12}
						lg={6}
						justifyContent='center'
						mx='auto'
						textAlign='center'
						pb={6}
					>
						<Typography variant='h2' mb={1}>
							Turn your idea into a startup
						</Typography>
						<Typography variant='body1' color='text'>
							We&apos;re constantly trying to express ourselves and actualize our
							dreams. If you have the opportunity to play{' '}
						</Typography>
					</Grid>
					<Grid container spacing={6}>
						<Grid item xs={12} md={6}>
							<CardOne>
								{/* <CardMedia
									sx={{ width: '30%' }}
									height='50'
									image={logo}
									alt='Paella dish'
								/> */}
								<CardHeader
									avatar={
										<Avatar sx={{ bgcolor: red[500] }} aria-label='recipe'>
											<RemoveRedEyeTwoToneIcon fontSize='large' />
										</Avatar>
									}
									title='Check our team'
									titleTypographyProps={{ variant: 'h3' }}
									subheader='People in this world shun people for being great. For being a bright color. For standing out.'
									subheaderTypographyProps={{ variant: 'body1' }}
									direction='center'
									// sx={{ width: '70%' }}
								/>
							</CardOne>
						</Grid>
						<Grid item xs={12} md={6}>
							<CardOne>
								<CardMedia
									sx={{ width: '30%' }}
									height='50'
									image={logo}
									alt='Paella dish'
								/>
								<CardHeader
									avatar={
										<Avatar sx={{ bgcolor: red[500] }} aria-label='recipe'>
											R
										</Avatar>
									}
									title='Check our team'
									titleTypographyProps={{ variant: 'h3' }}
									subheader='People in this world shun people for being great. For being a bright color. For standing out.'
									subheaderTypographyProps={{ variant: 'body1' }}
									direction='center'
									sx={{ width: '70%' }}
								/>
							</CardOne>
						</Grid>
						<Grid item xs={12} md={6}>
							<CardOne>
								<CardMedia
									sx={{ width: '30%' }}
									height='50'
									image={logo}
									alt='Paella dish'
								/>
								<CardHeader
									avatar={
										<Avatar sx={{ bgcolor: red[500] }} aria-label='recipe'>
											R
										</Avatar>
									}
									title='Check our team'
									titleTypographyProps={{ variant: 'h3' }}
									subheader='People in this world shun people for being great. For being a bright color. For standing out.'
									subheaderTypographyProps={{ variant: 'body1' }}
									direction='center'
									sx={{ width: '70%' }}
								/>
							</CardOne>
						</Grid>
						<Grid item xs={12} md={6}>
							<CardOne>
								<CardMedia
									sx={{ width: '30%' }}
									height='50'
									image={logo}
									alt='Paella dish'
								/>
								<CardHeader
									avatar={
										<Avatar sx={{ bgcolor: red[500] }} aria-label='recipe'>
											R
										</Avatar>
									}
									title='Check our team'
									titleTypographyProps={{ variant: 'h3' }}
									subheader='People in this world shun people for being great. For being a bright color. For standing out.'
									subheaderTypographyProps={{ variant: 'body1' }}
									direction='center'
									sx={{ width: '70%' }}
								/>
							</CardOne>
						</Grid>
					</Grid>
				</Container>
			</FeatureOne>

			<Section
				py={12}
				className='fullWithSection'
				sx={{ backgroundColor: '#f9faf6' }}
			>
				<MainContent maxWidth='lg' className='column is-10 is-offset-1'>
					<Grid container className='content' spacing={3}>
						<Grid item xs={12} sm={12}>
							<Typography variant='h1'>{mainpitch.title}</Typography>
							<Typography variant='h3'>{mainpitch.description}</Typography>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Typography variant='h3'>{heading}</Typography>
							<Typography variant='body1'>{description}</Typography>
						</Grid>
						<Features gridItems={intro.blurbs} />
						<Button size='large' variant='contained' component={Link} to='/products'>
							See all products
						</Button>
						<Grid item xs={12} sm={12}>
							<Typography variant='h3'>Latest stories</Typography>
							{/* <BlogRoll /> */}
							{/* <ProductList /> */}
							<Button variant='contained' component={Link} to='/blog' size='large'>
								Read more
							</Button>
						</Grid>
					</Grid>
				</MainContent>
			</Section>
		</>
	);
};

RMGLandingTemplate.propTypes = {
	image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	title: PropTypes.string,
	heading: PropTypes.string,
	subheading: PropTypes.string,
	mainpitch: PropTypes.object,
	description: PropTypes.string,
	intro: PropTypes.shape({
		blurbs: PropTypes.array,
	}),
};

const RMGLanding = ({ data }) => {
	const { frontmatter } = data.markdownRemark;
	const { markdownRemark: post } = data;

	return (
		<Layout>
			<RMGLandingTemplate
				image={frontmatter.image}
				title={frontmatter.title}
				heading={frontmatter.heading}
				subheading={frontmatter.subheading}
				mainpitch={frontmatter.mainpitch}
				description={frontmatter.description}
				intro={frontmatter.intro}
				contentComponent={HTMLContent}
				content={post.html}
			/>
		</Layout>
	);
};

RMGLanding.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default RMGLanding;

export const pageQuery = graphql`
	query RMGLandingTemplate {
		markdownRemark(frontmatter: { templateKey: { eq: "RMG-landing" } }) {
			html
			frontmatter {
				title
				image {
					childImageSharp {
						gatsbyImageData(quality: 100, layout: FULL_WIDTH)
					}
				}
				heading
				subheading
				mainpitch {
					title
					description
				}
				description
				intro {
					blurbs {
						text
						heading
					}
					heading
					description
				}
			}
		}
	}
`;
